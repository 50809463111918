import request from '@/utils/request'

// 获取电子刊详情（背景音乐）
export function getEbookDetail(params) {
  return request({
    url: '/ebook/detail',
    method: 'get',
    params
  })
}
// 获取电子刊页面数据
export function getEbookList(params) {
  return request({
    url: '/v2/ebook/list',
    method: 'get',
    params
  })
}
// 获取弹幕列表
export function getDanmuList(params) {
  return request({
    url: '/danmu/getDanmuList',
    method: 'get',
    params
  })
}
// 发送弹幕
export function sendDanmu(data) {
  return request({
    url: '/danmu/insertDanmu',
    method: 'post',
    data
  })
}

export default { getEbookDetail, getEbookList, getDanmuList, sendDanmu }