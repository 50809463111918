import axios from "axios";
// import router from "@/router/routers";
// import store from '../store'
// import Cookies from "js-cookie";

function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.href.split('?')[1].match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}

let baseURL = '';
if (process.env.NODE_ENV === "production") { // 生产环境
  baseURL = 'https://bs-h5.limitouch.com/interface';
  // baseURL = 'https://bs-test.limitouch.com:1443/interface';
}
else { // 开发环境
  baseURL = 'https://bs-test.limitouch.com:1443/interface';
}

// 创建axios实例
const service = axios.create({
  baseURL: baseURL,
  timeout: 600000, // 请求超时时间60秒
});

// request拦截器
service.interceptors.request.use(
  (config) => {
		const Token = getQueryString('token') || '';
    // alert(Token);
    if (Token) {
      config.headers["Authorization"] = 'Bearer ' + Token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // 兼容blob下载出错json提示
    if (
      error.response.data instanceof Blob &&
      error.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      const reader = new FileReader();
      reader.readAsText(error.response.data, "utf-8");
      reader.onload = function(e) {
        const errorMsg = JSON.parse(reader.result).message;
        alert(errorMsg);
      };
    } else {
      let code = 0;
      try {
        code = error.response.data.status;
      } catch (e) {
        if (error.toString().indexOf("Error: timeout") !== -1) {
          alert("网络请求超时");
          return Promise.reject(error);
        }
      }
      
      if (code) {
        if (code === 401) {
          // store.dispatch("LogOut").then(() => {
          //   // 用户登录界面提示
          //   Cookies.set("point", 401);
          //   location.reload();
          // });
        } else if (code === 403) {
          // router.push({ path: "/401" });
        } else {
          const errorMsg = error.response.data.message;
          if (errorMsg !== undefined) {
            alert(errorMsg);
          }
        }
      } else {
        alert("接口请求失败");
      }
    }
    return Promise.reject(error);
  }
);
export default service;
